/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Input,
  Text,
} from "@chakra-ui/react";
import IconBox from "components/icons/IconBox";
import { IconAdd, IconArrowBack } from "components/icons/Icons";
import { HSeparator } from "components/separator/Separator";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getCardsRequest } from "store/user/reducer";
import { cardsSelector, userSelector } from "store/user/selector";
import { ORDER_STEPS } from "types/data-types";

import CardRowItem from "./payment/CardRowItem";
import PayPalSelector from "./payment/PayPalSelector";
import { loadStripe, Stripe, StripeCardElement } from "@stripe/stripe-js";
import { updateProjectStatus } from "store/customerProject/reducer";
import { uploadProjectSelector } from "store/customerProject/selector";
import { axiosService } from "network/axios";
import { setAlertMessageRequest } from "store/ui/reducer";
import {
  calculateDueDate,
  fetchPerPagePrice,
  googleAnalytics,
} from "network/helper";
import { useNavigate, useParams } from "react-router-dom";
import TranslationPaymentHSF from "./TranslationPaymentHSF";

export default function TranslationPayment(props: {
  onChangeOrderStep?: Function;
}) {
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const user = useSelector(userSelector);
  const cards = useSelector(cardsSelector);
  const [stripe, setStripe] = useState<Stripe>(null);
  const [cardElement, setCardElement] = useState<StripeCardElement>(null);
  const [cardHolderName, setCardHolderName] = useState("");
  const [cardZipCode, setCardZipCode] = useState("");
  const uploadProject = useSelector(uploadProjectSelector);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const navigate = useNavigate();
  const [selectedCard, setSelectedCard] = useState(null);

  const isRedeemEnabled = localStorage.getItem("redeem") ? true : false;
  const orderSummary = useMemo(() => {
    let totalPrice = 0;
    let basePrice = 0;

    const targetLanguage = uploadProject.targetLanguage;

    const discountType =
      Number(user?.dicount?.discount_type ?? 0) === 0 ? "fixed" : "percent";
    const discountValue =
      user?.is_business === true ? Number(user?.discount?.discount ?? 0) : 0;

    const perPagePrice =
      discountType === "fixed"
        ? fetchPerPagePrice(targetLanguage) - discountValue
        : (fetchPerPagePrice(targetLanguage) * (100 - discountValue)) / 100;

    totalPrice =
      uploadProject.transitionType === "certified"
        ? uploadProject.totalPage * perPagePrice
        : uploadProject.totalWords * 0.1;

    basePrice =
      uploadProject.transitionType === "certified"
        ? uploadProject.totalPage * fetchPerPagePrice(targetLanguage)
        : uploadProject.totalWords * 0.1;

    if (isRedeemEnabled && uploadProject.transitionType === "certified") {
      totalPrice =
        (uploadProject.totalPage - 1 >= 0 ? uploadProject.totalPage - 1 : 0) *
        perPagePrice;
    }
    if (
      uploadProject.transitionType === "standard" &&
      uploadProject.totalWords < 250
    ) {
      basePrice = 20;
      totalPrice = 20;
    }

    if (uploadProject.isNotarized) {
      totalPrice += 19.95;
    }

    if (uploadProject.isMailHardCopy) {
      totalPrice += 15;
    }

    if (uploadProject.transitionSpeed === "express") {
      if (uploadProject.transitionType === "certified") {
        if (isRedeemEnabled) {
          totalPrice +=
            10 *
            (uploadProject.totalPage - 1 >= 0
              ? uploadProject.totalPage - 1
              : 0) *
            targetLanguage.length;
        } else {
          totalPrice += 10 * uploadProject.totalPage * targetLanguage.length;
        }
      } else {
        if (uploadProject.totalWords < 250) {
          totalPrice += 10 * targetLanguage.length;
        } else {
          totalPrice +=
            0.004 * uploadProject.totalWords * targetLanguage.length;
        }
      }
    }

    return {
      words: uploadProject.totalWords,
      pages: uploadProject.totalPage,
      totalPrice: totalPrice,
      langCnt: targetLanguage.length + 1,
      basePrice: basePrice,
    };
  }, [uploadProject, user, isRedeemEnabled]);

  useEffect(() => {
    dispatch(getCardsRequest({ user_id: user.user_id }));
    // updateInputValueByName('email', user.email);
    // updateInputValueByName('firstname', user.first_name);
    // updateInputValueByName('portal_total_price', orderSummary.totalPrice);
    // updateInputValueByName('order_id', orderId);

  }, []);

  const { onChangeOrderStep } = props;
  const boxShadow = "0px 3.60347px 45.0434px rgba(0, 0, 0, 0.1)";
  const [openCardInputForm, setOpenCardInputForm] = useState(false);
  useEffect(() => {
    if (cards?.length < 1) {
      setOpenCardInputForm(true);
    } else {
      setOpenCardInputForm(false);
    }
  }, [cards]);

  useEffect(() => {
    if (openCardInputForm) {
      dispatch(
        updateProjectStatus({
          paymentOption: null,
          saveCardAsDefault: false,
        })
      );
      setSelectedCard(null);
      initStripe();
    }
  }, [openCardInputForm]);

  const initStripe = async () => {
    let stripe = await loadStripe(
      // "pk_test_51NAyBnCsYOJWbJy3pNGgDnJ71UNGvBUXLikN2erIFcV6cUZlZxbVyWk8gB4AWlIBHCZFZeEexUyzDtfrkUHYMFKN002ZIoLdVq"
      "pk_live_51IEJJGJhA5ApiXVVzZnFak5wXrk8zGw7E1mDmbntMgUg5ZbERdHPmc5a0FfBqRuN6b55JuIzvnwShsSPs3eY4iQR00mCyIwUgj"
    );
    var elements = stripe.elements();
    let cardElement = elements.create("card", {
      iconStyle: "default",
      hidePostalCode: true,
      style: {
        base: {
          iconColor: "#000",
          color: "#000",
          fontWeight: 400,
          fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
          fontSize: "16px",
          lineHeight: "30px",
          letterSpacing: "normal",
          fontSmoothing: "antialiased",

          "::placeholder": {
            color: "#777",
          },
          ":-webkit-autofill": {
            color: "#777",
          },
        },
        invalid: {
          iconColor: "#F00",
          color: "#F00",
        },
      },
    });
    cardElement.mount("#card-element");
    cardElement.on("change", function (event) {
      if (event.complete) {
        setStripe(stripe);
        setCardElement(cardElement);
      } else if (event.error) {
        setStripe(null);
        setCardElement(null);
      }
    });
  };

  const onChangeCheckBox = (status: boolean) => {
    dispatch(
      updateProjectStatus({
        saveCardAsDefault: status,
      })
    );
  };

  const onPayNow = () => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://api-na1.hubapi.com/automation/v4/hwebhook-triggers/43773541/USaH7AC', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: user.email,
            firstname: user.first_name,
            "portal_total_price": orderSummary.totalPrice
          }),
        });
        const result = await response.json();
        console.log(result);
      } catch (error) {
        console.error('Error:', error);
      }
   };

  fetchData();
    if (selectedCard) {
      setIsProcessingPayment(true);
      onProceedPayment(null);
    } else {
      setIsProcessingPayment(true);
      if (uploadProject.saveCardAsDefault === true) {
        stripe
          .createPaymentMethod({
            type: "card",
            card: cardElement,
            billing_details: {
              name: cardHolderName,
            },
          })
          .then(async function (result) {
            const paymentMethodId = result?.paymentMethod?.id;
            await axiosService.post(
              `/shipping/put/payment-method/${user.user_id}`,
              {
                payment_method_id: paymentMethodId,
                is_default: 1,
              }
            );
            generateTokenId();
          })
          .catch((err: any) => {
            setIsProcessingPayment(false);
            dispatch(
              setAlertMessageRequest({
                type: "error",
                message:
                  "Error in generating token Id. please check the card information.",
              })
            );
            setIsProcessingPayment(false);
          });
      } else {
        generateTokenId();
      }
    }
  };

  const generateTokenId = () => {
    if (stripe) {
      stripe
        .createToken(cardElement, {
          name: cardHolderName,
          address_zip: cardZipCode,
        })
        .then(async (res) => {
          const tokenId = res?.token?.id;
          if (tokenId) {
            onProceedPayment(tokenId);
          } else if (res?.error?.message) {
            dispatch(
              setAlertMessageRequest({
                type: "error",
                message:
                  res?.error?.message ||
                  "Error in processing the payment. Please contact to support team.",
              })
            );
            setIsProcessingPayment(false);
          } else {
            dispatch(
              setAlertMessageRequest({
                type: "error",
                message:
                  "Error in generating token Id. please check the card information.",
              })
            );
            setIsProcessingPayment(false);
          }
        })
        .catch(async (err) => {
          dispatch(
            setAlertMessageRequest({
              type: "error",
              message:
                err?.error?.message ||
                "Error in processing the payment. Please contact to support team.",
            })
          );
          setIsProcessingPayment(false);
        });
    } else {
      onProceedPayment(null);
    }
  };

  const onProceedPayment = (tokenId: string) => {
    if (tokenId) {
      let paymentPayload = {
        amount: orderSummary.totalPrice,
        token: tokenId,
        isRedeem: isRedeemEnabled,
      };
      try {
        axiosService
          .post(`/shipping/process-token-payment/${orderId}`, paymentPayload)
          .then((res: any) => {
            if (res?.data?.payment_status === "succeeded") {
              completeOrder(2);
            } else {
              const alertMessage =
                "Payment is not completed. Please contact so support team to confirm the payment status.";
              dispatch(
                setAlertMessageRequest({
                  type: "warning",
                  message: alertMessage,
                })
              );
              completeOrder(1);
            }
            // Check the Payment Status
          })
          .catch((err: any) => {
            const error =
              err.data.messages.error ||
              "Error in proceeding the payment. Please contact to support team.";
            dispatch(
              setAlertMessageRequest({
                type: "error",
                message: error,
              })
            );
            setIsProcessingPayment(false);
          });
      } catch (err: any) {}
    } else {
      if (selectedCard) {
        if (selectedCard?.pm_id) {
          axiosService
            .post(`/shipping/process-pm-payment/${orderId}`, {
              amount: orderSummary?.totalPrice,
              pm_id: selectedCard?.pm_id,
              customer_id: selectedCard?.customer_id,
              isRedeem: isRedeemEnabled,
            })
            .then((res: any) => {
              if (res?.data?.payment_status === "succeeded") {
                completeOrder(2);
              } else {
                const alertMessage =
                  "Payment is not completed. Please contact so support team to confirm the payment status.";
                dispatch(
                  setAlertMessageRequest({
                    type: "warning",
                    message: alertMessage,
                  })
                );
                completeOrder(1);
              }
            })
            .catch((err: any) => {
              const error =
                err.data.messages.error ||
                "Error in proceeding the payment. Please contact to support team.";
              dispatch(
                setAlertMessageRequest({
                  type: "error",
                  message: error,
                })
              );
              setIsProcessingPayment(false);
            });
        }
      }
    }
  };

  const completeOrder = (order_status: number) => {
    const payload = {
      ...uploadProject,
      order_id: orderId,
      document_cnt: uploadProject.documents?.length || 0,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      pageCount: uploadProject.totalPage,
      wordsCount: uploadProject.totalWords,
      price: orderSummary.totalPrice,
      order_due_date: calculateDueDate(uploadProject),
      orderStatus: order_status,
    };

    axiosService
      .post(`/projects/${user.user_id}`, payload)
      .then((res) => {
        localStorage.removeItem("orderStep");
        localStorage.removeItem("uploadProject");

        googleAnalytics(uploadProject, orderSummary, user, orderId);


        localStorage.removeItem("unfinished_payload");
        document.getElementById("btn-hubspot-submit").click();
        setIsProcessingPayment(false);
      })
      .catch((err: any) => {
        const error =
          err.data.messages.error ||
          "Error in proceeding the payment. Please contact to support team.";
        dispatch(
          setAlertMessageRequest({
            type: "error",
            message: error,
          })
        );

        setIsProcessingPayment(false);
      });
  };

  const getIframeIdFromDiv = (id: string) => {
    const divElement = document.getElementById(id);
    
    if (divElement) {
      const iframe = divElement.querySelector('iframe');
      
      if (iframe) {
        return iframe.id;
      } else {
        console.error('No iframe found within the specified div.');
        return null;
      }
    } else {
      console.error(`Div with ID "${id}" not found.`);
      return null;
    }
  }

  // Usage
  const iframeId = getIframeIdFromDiv('hsf_tracking_form');
  console.log('Iframe ID:', iframeId);

  const updateInputValueByName = (inputName: string, newValue: any) => {
    // Select the input element using the name attribute
    const iframeId = getIframeIdFromDiv('hsf_tracking_form');
    const iframe = document.getElementById(iframeId) as HTMLIFrameElement;

    // Check if the iframe exists
    if (iframe) {
        // Access the iframe's document
        const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

        // Select the input element using the name attribute
        const inputElement = iframeDocument?.querySelector(`input[name="${inputName}"]`) as HTMLInputElement;

        // Check if the input element exists
        if (inputElement) {
            // Update the value
            inputElement.value = newValue;
            console.log(`Updated ${inputName} to: ${newValue}`);
        } else {
            console.error(`Input with name "${inputName}" not found in the iframe.`);
        }
    } else {
        console.error(`Iframe with ID "${iframeId}" not found.`);
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      rowGap="32px"
      flex={1}
      height="100%"
      padding={{ lg: "40px 35px", md: "30px 25px", base: "20px 0px" }}
      bgColor="#FFF"
      boxShadow={{ md: boxShadow, base: "none" }}
      borderRadius="15px"
    >
      <Box mb="30px">
        <Heading
          fontSize="18px"
          lineHeight={"150%"}
          fontWeight="700"
          textTransform="capitalize"
          color="#000"
        >
          Payment
        </Heading>
        <Box mt="20px">
          <>
            <CardRowItem
              onSelect={(card: any) => {
                setSelectedCard(card);
                setOpenCardInputForm(false);
              }}
              mb="15px"
            />
            {!openCardInputForm && (
              <Flex
                alignItems="center"
                w="fit-content"
                cursor="pointer"
                onClick={(e) => {
                  setCardHolderName("");
                  setCardZipCode("");
                  setOpenCardInputForm(true);
                }}
              >
                <IconBox
                  w="22px"
                  h="22px"
                  mr="10px"
                  icon={<IconAdd w="22px" h="22px" />}
                />
                <Text
                  fontSize="14px"
                  lineHeight="22px"
                  textDecoration="underline"
                >
                  Add a card or bank
                </Text>
              </Flex>
            )}

            {openCardInputForm && (
              <>
                <Box
                  padding={{ md: "25px", base: "20px" }}
                  border="1px solid #DCDCDC"
                  borderRadius="10px"
                >
                  <Box
                    padding="15px"
                    borderRadius="50px"
                    border="1px solid #E9E9E9"
                  >
                    <Box id="card-element"></Box>
                  </Box>
                  <Flex id="name-on-card" mt="20px" columnGap="20px">
                    <Input
                      name="name-on-card"
                      borderRadius="50px"
                      border="1px solid #E9E9E9"
                      background="#FFF"
                      sx={{
                        width: "65%",
                        padding: "15px 20px !important",
                        height: "60px",
                      }}
                      placeholder="Name On Card"
                      _placeholder={{
                        color: "#777",
                        fontSize: "16px !important",
                      }}
                      value={cardHolderName}
                      onChange={(e) => {
                        // setCardHolderError(null);
                        setCardHolderName(e.target.value);
                      }}
                    />
                    <Input
                      name="postal"
                      borderRadius="50px"
                      border="1px solid #E9E9E9 !important"
                      sx={{
                        width: "35%",
                        padding: "15px 20px !important",
                        height: "60px",
                      }}
                      placeholder="Zipcode"
                      _placeholder={{
                        color: "#777",
                        fontSize: "16px !important",
                      }}
                      value={cardZipCode}
                      onChange={(e) => {
                        setCardZipCode(e.target.value);
                      }}
                    />
                  </Flex>
                  <Box mt="10px">
                    <Checkbox
                      sx={{
                        ".chakra-checkbox__control": {
                          borderColor: "#2F80ED",
                        },
                      }}
                      onChange={(e: any) => onChangeCheckBox(e.target.checked)}
                    >
                      Use this card information in future
                    </Checkbox>
                  </Box>
                </Box>
              </>
            )}
          </>
        </Box>

        <Flex align="center" my="15px">
          <HSeparator />
          <Text color="gray.400" p="5px 15px" textTransform={"uppercase"}>
            or
          </Text>
          <HSeparator />
        </Flex>

        <PayPalSelector />
      </Box>
      <Flex marginTop="auto" alignItems="center" justifyContent="space-between">
        <Flex
          alignItems="center"
          fontSize="14px"
          lineHeight="17px"
          color="#2F80ED"
          fontWeight="700"
          cursor="pointer"
          onClick={() =>
            onChangeOrderStep(ORDER_STEPS.SET_TRANSITION_DETAILS_STEP)
          }
        >
          <IconBox
            w="25px"
            h="24px"
            mr="10px"
            icon={<IconArrowBack w="25px" h="24px" />}
          />
          Back
        </Flex>
        <Button
          type="button"
          w="fit-content"
          height="44px"
          padding="13px 26px"
          borderRadius="100px"
          bgColor="#2F80ED"
          color="#FFF"
          fontSize="14px"
          lineHeight="14px"
          fontWeight="bold"
          isDisabled={
            (!selectedCard &&
              (!(
                stripe &&
                cardElement &&
                !_.isEmpty(cardHolderName) &&
                !_.isEmpty(cardZipCode)
              ) ||
                isProcessingPayment)) ||
            (!selectedCard &&
              !(
                stripe &&
                cardElement &&
                !_.isEmpty(cardHolderName) &&
                !_.isEmpty(cardZipCode)
              ))
          }
          isLoading={isProcessingPayment}
          onClick={() => {
            onPayNow();
          }}
          _hover={{
            bgColor: "#3311DB !important",
          }}
        >
          Pay Now
        </Button>
      </Flex>
      <form
        style={{ display: "none" }}
        action={`/customer/order/completed/${orderId}`}
        id="hsf_tracking_form"
      >
        <button type="submit" id="btn-hubspot-submit">
          Test
        </button>
        <input
          type="text"
          id="order_id"
          name="order_id"
          value={`${orderId}`}
        />
        <input
          type="text"
          id="full_name"
          name="full_name"
          value={`${user.first_name} ${user.last_name}`}
        />
        <input
          type="text"
          id="email_address"
          name="email_address"
          value={`${user.email}`}
        />
        <input
          type="text"
          id="total_pages"
          name="total_pages"
          value={uploadProject.totalPage}
        />
        <input
          type="text"
          id="total_words"
          name="total_words"
          value={uploadProject.totalWords}
        />
        <input
          type="text"
          id="source_language"
          name="source_language"
          value={uploadProject.sourceLanguage}
        />
        <input
          type="text"
          id="target_language"
          name="target_language"
          value={uploadProject.targetLanguage?.join(", ")}
        />
        <input
          type="text"
          id="portal_total_price"
          name="portal_total_price"
          value={orderSummary.totalPrice}
        />
      </form>
    </Box>
  );
}
