import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";

type ProjectsState = {
  loading: boolean;
  projects: {
    active_projects: any[];
    completed_projects: any[];
    due_soon_projects: any[];
    new_projects: any[];
    review_projects: any[];
  };
  isFetchingProjectDetail: boolean;
  selectedProject: any;
  isUpdatingDocuments: boolean;

  isGettingTranslatorProjects: boolean;
  translatorProjects: any[];

  isGettingManagerProjects: boolean;
  managerProjects: any[];

  isDownloadingOriginFiles: boolean;
  isDownloadingTranslationFiles: boolean;
  isFetchingReceivers: boolean;
  isFetchingMessages: boolean;
  isSendingMessage: boolean;
  receivers: any[];
  messages: any;
  isFetchingUnreadMessages: boolean;
  unreadMessages: any;
  draftProjectsCnt: number;
  notificationDetails: any[];
  isReadingMessage: boolean;
  isGettingManagers: boolean;
  managers: any[];
};

const initialState: ProjectsState = {
  loading: true,
  projects: {
    active_projects: [],
    completed_projects: [],
    due_soon_projects: [],
    new_projects: [],
    review_projects: [],
  },
  isFetchingProjectDetail: true,
  selectedProject: null,
  isUpdatingDocuments: false,

  isGettingTranslatorProjects: false,
  translatorProjects: null,

  isGettingManagerProjects: false,
  managerProjects: null,

  isDownloadingOriginFiles: false,
  isDownloadingTranslationFiles: false,

  isFetchingReceivers: false,
  isFetchingMessages: false,
  isSendingMessage: false,
  receivers: [],
  messages: [],
  isFetchingUnreadMessages: false,
  unreadMessages: [],
  draftProjectsCnt: 0,
  notificationDetails: [],
  isReadingMessage: false,
  isGettingManagers: false,
  managers: [],
};

const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    getProjectsRequest(state) {
      state.loading = true;
      state.projects = {
        active_projects: [],
        completed_projects: [],
        due_soon_projects: [],
        new_projects: [],
        review_projects: [],
      };
    },
    getProjectsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.projects = action.payload;
    },

    getProjectDetailRequest(state, action: PayloadAction<any>) {
      state.isFetchingProjectDetail = true;
      state.selectedProject = null;
    },

    getProjectDetailResponse(state, action: PayloadAction<any>) {
      state.isFetchingProjectDetail = false;
      state.selectedProject = action.payload;
    },

    clearSelectedProjectRequest(state) {
      state.selectedProject = null;
    },

    updateDocumentReqeust(state, action: PayloadAction<any>) {
      state.isUpdatingDocuments = true;
    },

    updateDocumentRequestSuccess(state, action: PayloadAction<any>) {
      state.isUpdatingDocuments = false;
      if (action?.payload) {
        state.selectedProject = action.payload;
      }
    },

    updateDocumentRequestFailed(state) {
      state.isUpdatingDocuments = false;
    },

    getTranslatorProjects(state, action: PayloadAction<any>) {
      state.isGettingTranslatorProjects = true;
    },

    getTranslatorProjectsSuccess(state, action: PayloadAction<any>) {
      state.isGettingTranslatorProjects = false;
      state.translatorProjects = action.payload;
    },

    getTranslatorProjectsFailed(state) {
      state.isGettingTranslatorProjects = false;
    },

    getTranslatorProjectDetailRequest(state, action: PayloadAction<any>) {
      state.isFetchingProjectDetail = true;
      state.selectedProject = null;
    },

    getTranslatorProjectDetailRequestSuccess(
      state,
      action: PayloadAction<any>
    ) {
      state.isFetchingProjectDetail = false;
      state.selectedProject = action.payload;
    },

    //
    getManagerProjects(state, action: PayloadAction<any>) {
      state.isGettingManagerProjects = true;
    },

    getManagerProjectsSuccess(state, action: PayloadAction<any>) {
      state.isGettingManagerProjects = false;
      state.managerProjects = action.payload;
    },

    getManagerProjectsFailed(state) {
      state.isGettingManagerProjects = false;
    },

    getManagerProjectDetailsRequest(state, action: PayloadAction<any>) {
      state.isFetchingProjectDetail = true;
      state.selectedProject = null;
    },

    getManagerProjectDetailsRequestSuccess(state, action: PayloadAction<any>) {
      state.isFetchingProjectDetail = false;
      state.selectedProject = action.payload;
    },

    requestDownloadOriginFile(state, action: PayloadAction<any>) {
      state.isDownloadingOriginFiles = true;
    },
    requestDownloadOriginFileSuccess(state) {
      state.isDownloadingOriginFiles = false;
    },
    requestDownloadOriginFileFailed(state, action: PayloadAction<any>) {
      state.isDownloadingOriginFiles = false;
    },

    getReceiversRequest(state, action: PayloadAction<any>) {
      state.isFetchingReceivers = true;
    },
    getReceiversRequestSuccess(state, action: PayloadAction<any>) {
      state.receivers = _.uniqBy(action.payload, "user_id");
      state.isFetchingReceivers = false;
    },
    getReceiversRequestFailed(state, action: PayloadAction<any>) {
      state.isFetchingReceivers = false;
    },

    getMessagesRequest(state, action: PayloadAction<any>) {
      state.isFetchingMessages = true;
    },
    getMessagesRequestSuccess(state, action: PayloadAction<any>) {
      state.isFetchingMessages = false;
      state.messages = action.payload;
    },
    getMessagesRequestFailed(state, action: PayloadAction<any>) {
      state.isFetchingMessages = false;
    },

    sendMessageRequest(state, action: PayloadAction<any>) {
      state.isSendingMessage = true;
    },
    sendMessageRequestSuccess(state, action: PayloadAction<any>) {
      state.isSendingMessage = false;
    },
    sendMessageRequestFailed(state, action: PayloadAction<any>) {
      state.isSendingMessage = false;
    },

    getUnreadMessagesRequest(state, action: PayloadAction<any>) {
      state.isFetchingUnreadMessages = true;
    },
    getUnreadMessagesRequestSuccess(state, action: PayloadAction<any>) {
      state.draftProjectsCnt = action.payload?.drafts || 0;
      state.unreadMessages = action.payload?.unread || {};
      state.notificationDetails = action.payload?.notificationDetails || [];
      state.isFetchingUnreadMessages = false;
    },
    getUnreadMessagesRequestFailed(state, action: PayloadAction<any>) {
      state.isFetchingUnreadMessages = false;
    },

    changeReadStatusMessageRequest(state, action: PayloadAction<any>) {
      state.isReadingMessage = false;
    },

    changeReadStatusMessageRequestSuccesss(state, action: PayloadAction<any>) {
      console.log(action);
    },

    getManagersRequest(state) {
      state.isGettingManagers = true;
      state.managers = [];
    },

    getManagersRequestSuccess(state, action: PayloadAction<any>) {
      state.isGettingManagers = false;
      state.managers = action.payload.managers;
    },
    getManagersRequestFailed(state, action: PayloadAction<any>) {
      state.isGettingManagers = false;
      state.managers = [];
    },
  },
});

export const {
  clearSelectedProjectRequest,

  getProjectsRequest,
  getProjectsSuccess,
  getProjectDetailRequest,
  getProjectDetailResponse,
  updateDocumentReqeust,
  updateDocumentRequestSuccess,
  updateDocumentRequestFailed,

  getTranslatorProjects,
  getTranslatorProjectsSuccess,
  getTranslatorProjectsFailed,

  getTranslatorProjectDetailRequest,
  getTranslatorProjectDetailRequestSuccess,

  getManagerProjects,
  getManagerProjectsSuccess,
  getManagerProjectsFailed,

  getManagerProjectDetailsRequest,
  getManagerProjectDetailsRequestSuccess,

  requestDownloadOriginFile,
  requestDownloadOriginFileSuccess,
  requestDownloadOriginFileFailed,

  getReceiversRequest,
  getReceiversRequestSuccess,
  getReceiversRequestFailed,
  getMessagesRequest,
  getMessagesRequestSuccess,
  getMessagesRequestFailed,
  sendMessageRequest,
  sendMessageRequestSuccess,
  sendMessageRequestFailed,

  getUnreadMessagesRequest,
  getUnreadMessagesRequestSuccess,
  getUnreadMessagesRequestFailed,

  changeReadStatusMessageRequest,
  changeReadStatusMessageRequestSuccesss,

  getManagersRequest,
  getManagersRequestSuccess,
  getManagersRequestFailed,
} = projectsSlice.actions;

export default projectsSlice.reducer;
